import './Header.css';
import { Link } from "react-router-dom";

function Header() {
  return (
    <ul className="header">
      <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li>
    </ul>
  )
}

export default Header;