import './Privacy.css';
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="privacy-container">
      <div className="privacy-policy">
        <Link to="/"><>&#8592;</> Back Home</Link>
        <h2>Privacy Policy</h2>
        <div className="privacy-section">
          <h3>General Privacy</h3>
          <p>Our policy is pretty straightforward. Helping Friendly App does not store any of your personal information. Your show data is local to your device unless you have linked a Phish.net account. Linking a Phish.net account simply imports your show data and stores a reference to show IDs local on your device. Our server is simply setup to bring in new show data to your device but does not collect any information about shows you've attended, heard or rated in the app.</p>
        </div>
        <div className="privacy-section">
          <h3>Phish.net</h3>
          <p>Phish.net accounts are used to import shows into the app and sync with Phish.net when you like shows from the app. Any data including email address, name and login used in The Helping Friendly App is sent directly to the Phish.net API using HTTPS and is compliant with the <a href="https://docs.phish.net/terms-of-use">Phish.net API terms of use</a>. Phish.net accounts are subject to the <a href="https://phish.net/page/terms-of-use">Phish.net terms of use</a>.</p>
        </div>
      </div>
    </div>
  )
}

export default Privacy;