import logo from './AppIcon1024.png';
import appStore from './appstore.svg';
import './App.css';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>The Helping Friendly App</code>
        </p>
        <p>Read the Book</p>
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/helping-friendly-app/id360564876"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={appStore} className="App-Logo" alt="App Store Link" />
        </a>
      </header>
      <div className="privacy-links">
          <Header />
      </div>
    </div>
  );
}

export default App;
